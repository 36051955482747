<template>
  <div class="Banner">
    <img src="../../../assets/img/ExpertBanner.png" alt="" />
    <div class="txt">
      <p>
        艺途国际举办的艺术家客座教授受聘仪式，汇集了音乐、舞蹈、影视表演界的20余位艺术家，中国网、中华网、中国教育网等15家重量级媒体对本次研讨会进行了新闻报道。在研讨会之后,艺术家们在“1V1访谈”中,进一步阐述了对艺术教育工作的新感想,同时指出了当下艺术教育存在的问题,并给出了建设性的解决方案。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpertBanner",
};
</script>

<style lang="less" scoped>
.Banner {
  margin-top: 60px;
  position: relative;
  img {
    width: 100%;
  }
  .txt {
    position: absolute;
    bottom: 6px;
    width: 100%;
    height: 80px;
    width: 990px;
    background:#07060796;
    left: 50%;
    transform: translate(-50%);
    color: white;
    p{
      text-indent: 2rem;
      line-height: 26px;
    }
  }
}
</style>