<template>
  <div>
    <title-yt />
    <nav-top />
    <expert-banner />
    <infoVideo />
    <copyright />
    <!-- <medio-video/> -->
  </div>
</template>

<script>
import Copyright from "../../pubilic/copyright.vue";
import NavTop from "../../pubilic/NavTop.vue";
import TitleYt from "../../pubilic/TitleYt.vue";
import ExpertBanner from "./ExpertBanner.vue";
import infoVideo from "./infoVideo.vue";
import MedioVideo from "./medioVideo.vue";

export default {
  components: {
    NavTop,
    TitleYt,
    ExpertBanner,
    infoVideo,
    Copyright,
    MedioVideo,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
</style>