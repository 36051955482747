<template>
  <div class="infoVideo">
    <div class="txt-info w">
      <p>
        艺途国际举办的艺术家客座教授受聘仪式，汇集了音乐、舞蹈、影视表演界的20余位艺术家，他们中有多位一直是当代艺术中的主要力量,其代表作品中展现了扎实的基本功和优秀的创作才能,并且仍然处于艺术创作的高峰,活跃于中国当代艺术舞台上。其中，中国网、中华网、中国教育网等15家重量级媒体对本次研讨会进行了新闻报道。
        此外,在研讨会之后,艺术家们在“1V1访谈”中,进一步阐述了对艺术教育工作的新感想,同时指出了当下艺术教育存在的问题,并给出了建设性的解决方案。
      </p>
    </div>
    <div class="professor w">
      <!-- 教授 -->
      <div class="title">
        <h3>客座教授</h3>
      </div>
      <div class="professor-list">
        <div class="professor-img">
          <img
            v-for="(name, index) in nameList1"
            :key="index"
            :src="name"
            v-lazy-img="name"
          />
        </div>
        <div class="professor-img">
          <img
            v-for="(name, index) in nameList2"
            :key="index"
            :src="name"
            v-lazy-img="name"
          />
        </div>
      </div>
    </div>
    <div class="video">
      <div class="title">
        <h3>1V1访谈视频</h3>
      </div>
      <div class="videoList w">
        <div class="box" v-for="(item, i) in filteredVideoList" :key="i">
          <img
            class="playIcon"
            src="../../../assets/img/playVideo.png"
            @click="PlayVideo(i + 1)"
            v-show="item.iconShow"
          />
          <div
            class="closeBtn"
            v-show="!item.iconShow"
            ref="closeBtn"
            @click="closeVideo(i)"
          >
            <i class="el-icon-circle-close"></i>
          </div>
          <video
            :src="item.playUrl"
            :poster="item.imgurl"
            ref="videoPlayers"
            v-lazy-poster="item.imgurl"
          ></video>

          <h3 v-show="item.iconShow">{{ item.txt }}</h3>
        </div>
      </div>
      <div class="more" v-if="!showAllVideos">
        <h3 @click="showAllVideos = true">查看更多</h3>
      </div>
    </div>
  </div>
</template>

<script>
// 在组件中引入
import axios from "axios";
import Hls from "hls.js";
export default {
  // 注册
  name: "infoVideo",

  data() {
    return {
      showAllVideos: false, // 控制视频列表的显示状态
      nameList1: [],
      nameList2: [],
      videoList: [
        {
          imgurl: require("../../../assets/img/video01.png"),
          txt: "廖先冀：如何看待当前全民学钢琴的热潮？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video02.png"),
          txt: "刘翠玉：老师如何提升教学水平？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video03.png"),
          txt: "康曦：如何看待线上声乐教学？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video04.png"),
          txt: "仲达文：专业主持人和播音主持教师存在哪些差异？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video05.png"),
          txt: "刘翠玉：小朋友为什么要学习舞蹈？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video06.png"),
          txt: "潘军：如何提高孩子学习音乐的兴趣？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video03.png"),
          txt: "康曦：学习声乐应该注意什么？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video07.png"),
          txt: "宋丽华：您给学习声乐的孩子们有什么好的建议吗？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video08.png"),
          txt: "于春仪：小班舞蹈创编需要注意什么？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video11.png"),
          txt: "李蓝紫：您认为舞台表现力是天生的还是后天练出来的？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video11.png"),
          txt: "李蓝紫：您觉得内心的人适合舞台表演吗？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video11.png"),
          txt: "李蓝紫：您在刻画王熙凤这个角色的时候有没有自己的小心得？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video09.png"),
          txt: "胡献军：您认为国内书法教学现状如何？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video09.png"),
          txt: "胡献军：您认为书法可以自学吗？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video09.png"),
          txt: "胡献军：学习书法，好老师、好课程、好学校哪个更重要？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video10.png"),
          txt: "孙鹏：学习舞蹈天赋和勤奋哪个更重要？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video06.png"),
          txt: "潘军：少儿唱歌入门应该选择哪类歌曲？",
          playUrl: "",
          iconShow: true,
        },
        {
          imgurl: require("../../../assets/img/video09.png"),
          txt: "胡献军：您对艺途国际搭建书法教学体系有什么建议吗？",
          playUrl: "",
          iconShow: true,
        },
      ],
    };
  },
  mounted() {
    this.populateNameLists();
  },
  methods: {
    PlayVideo(id) {
      const newId = id;
      console.log(id);
      const index = id - 1;
      const videoElement = this.$refs.videoPlayers[index];
      const close = this.$refs.closeBtn[index];

      const positions = [
        { left: "0", right: "-670px", top: "-220px" },
        { left: "-339px", right: "-335px", top: "-219px" },
        { left: "-678px", right: "0", top: "-220px" },
        { left: "-0", right: "-670px", top: "-525px" },
        { left: "-339px", right: "-335px", top: "-525px" },
        { left: "-678px", right: "0", top: "-525px" },
        { left: "-0", right: "-670px", top: "-220px" },
        { left: "-339px", right: "-335px", top: "-219px" },
        { left: "-678px", right: "0", top: "-220px" },
        { left: "-0", right: "-670px", top: "-220px" },
        { left: "-339px", right: "-335px", top: "-219px" },
        { left: "-678px", right: "0", top: "-220px" },
        { left: "-0", right: "-670px", top: "-220px" },
        { left: "-339px", right: "-335px", top: "-219px" },
        { left: "-678px", right: "0", top: "-220px" },
        { left: "-0", right: "-670px", top: "-220px" },
        { left: "-339px", right: "-335px", top: "-219px" },
        { left: "-678px", right: "0", top: "-220px" },
      ];

      axios
        .get("https://www.yituedu.cn:3001/video/resultUrl", {
          params: {
            id: newId,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            // 赋值
            this.videoList[index].playUrl = response.data.videoUrl;
            this.videoList[index].iconShow = false;

            this.$nextTick(() => {
              // 添加蒙版
              document.body.className = "mengban";
              // 放大视频
              videoElement.className = "zoomVideo";
              videoElement.style.left = positions[index].left;
              videoElement.style.top = positions[index].top;
              close.style.right = positions[index].right;
              if (index === 3 || index === 4 || index === 5) {
                close.style.top = "-300px";
              }
              // 使用hls.js播放m3u8格式的视频
              if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(response.data.videoUrl);
                hls.attachMedia(videoElement);
              } else if (
                videoElement.canPlayType("application/vnd.apple.mpegurl")
              ) {
                videoElement.src = response.data.videoUrl;
              }
              // 视频播放
              videoElement.play();
            });
          }
        });
    },

    closeVideo(index) {
      const videoElement = this.$refs.videoPlayers[index];
      videoElement.className = "";
      videoElement.pause();
      this.videoList[index].iconShow = true;
      this.videoList[index].playUrl = "";
      document.body.className = "";
    },
    // 动态插入图片
    populateNameLists() {
      for (let i = 1; i <= 8; i++) {
        const imageName = `name${i}.png`;
        const imagePath = require(`../../../assets/img/name/${imageName}`);
        this.nameList1.push(imagePath);
      }
      for (let i = 9; i <= 15; i++) {
        const imageName = `name${i}.png`;
        const imagePath = require(`../../../assets/img/name/${imageName}`);
        this.nameList2.push(imagePath);
      }
    },
  },
  computed: {
    filteredVideoList() {
      // 根据showAllVideos来过滤视频列表
      return this.showAllVideos ? this.videoList : this.videoList.slice(0, 6);
    },
  },
};
</script>
<style lang="less" scoped>
.infoVideo {
  .txt-info {
    margin: 30px auto;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    line-height: 33px;
  }
  .professor {
    .title {
      text-align: center;
      margin: 50px auto;
      h3 {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
      }
    }
    .professor-list {
      .professor-img {
        height: auto;
        img {
          width: 77px;
          height: 42px;
          margin-right: 48px;
          margin-bottom: 23px;
        }
      }
    }
  }
  .video {
    position: relative;
    .zoomVideo {
      width: 1000px !important;
      height: 1000px !important;
      position: absolute !important;
      z-index: 2 !important;
    }

    .title {
      text-align: center;
      margin: 50px auto;

      h3 {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
      }
    }

    .videoList {
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .box {
        width: 322px;
        height: auto;
        cursor: pointer;
        margin-bottom: 20px;
        position: relative;

        .playIcon {
          left: 50%;
          position: absolute;
          transform: translate(-50%);
          top: 25%;
          z-index: 1;
        }
        .closeBtn {
          width: 50px;
          height: 50px;
          position: absolute;
          right: 0;
          z-index: 9;
          font-size: 50px;
        }
        video {
          width: 322px;
          height: 201px;
        }

        h3 {
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          line-height: 28px;
          text-align: left;
          padding-top: 20px;
        }
      }
    }
  }
  .more {
    width: 179px;
    height: 41px;
    background: #e20305;
    border-radius: 21px;
    margin: 0 auto;
    margin-top: 100px;
    text-align: center;
    line-height: 41px;
    margin-bottom: 100px;
    cursor: pointer;
    h3 {
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
</style>