<template>
  <div>
    <video ref="videoElement" controls></video>
  </div>
</template>

<script>
import Hls from "hls.js";

export default {
  mounted() {
    const video = this.$refs.videoElement;
    const videoSrc = "https://www.yituedu.cn:3000/videos/Video1.m3u8";
    https://www.yituedu.cn:3000/videos/1.m3u8
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(video);
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = videoSrc;
    }
  },
};
</script>
